import googleTagManager from '@analytics/google-tag-manager';
import Analytics from 'analytics';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import ApiDisplay from './pages/ApiDisplay';
import './pages/Components/Header/NavbarPage.css';
import NevadaLegal from './pages/Legal/NevadaLegal';
import NevadaLegalSubmit from './pages/Legal/NevadaLegalSubmit';
import Main from './pages/Main';
import './pages/Media/MediaRoom.css';
import NewsDetails from './pages/Media/NewsDetails';
import NewsRelease from './pages/Media/NewsRelease';
import RedirectorPreview from './pages/RedirectorPreview';

Analytics({
  app: 'Brinker.com',
  plugins: [
    googleTagManager({
      containerId: 'GTM-NZNBXC3',
    }),
  ],
});
function App() {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/welcome"
          render={() => <Main uid="welcome" nosubheader />}
        />
        <Route
          exact
          path="/"
          render={() => <Main uid="welcome" nosubheader />}
        />
        <Route
          exact
          path="/welcome/brands"
          render={() => <Main uid="brands" />}
        />
        <Redirect exact from="/welcome/brands/*" to="/welcome/brands" />
        <Route
          exact
          path="/welcome/leadership"
          render={() => <Main uid="leadership" />}
        />
        <Route
          exact
          path="/welcome/humblebrags"
          render={() => <Main uid="humblebrags" />}
        />
        <Route
          exact
          path="/welcome/community-matters"
          render={() => <Main uid="community-matters" />}
        />
        <Route
          exact
          path="/welcome/community-matters/brinker"
          render={() => <Main uid="community-matters_brinker" />}
        />
        <Route
          exact
          path="/welcome/community-matters/chilis"
          render={() => <Main uid="community-matters_chilis" />}
        />
        <Route
          exact
          path="/welcome/community-matters/maggianos"
          render={() => <Main uid="community-matters_maggianos" />}
        />
        <Route
          exact
          path="/franchise"
          render={() => <Main uid="franchise" nosubheader />}
        />
        <Route
          exact
          path="/franchise/domestic"
          render={() => <Main uid="domestic" nosubheader />}
        />
        <Route
          exact
          path="/franchise/global"
          render={() => <Main uid="global" nosubheader />}
        />
        <Redirect exact from="/commitment/*" to="/sustainability" />
        <Redirect exact from="/commitment" to="/sustainability" />

        <Route
          exact
          path="/sustainability"
          render={() => <Main uid="sustainability" />}
        />
        <Route
          exact
          path="/media/hot-off-the-press"
          component={NewsRelease}
        />
        <Route exact path="/media/newsdetails" component={NewsDetails} />
        <Route
          exact
          path="/media/multimedia"
          render={() => <Main uid="multimedia" />}
        />
        <Route
          exact
          path="/contacts"
          render={() => <Main uid="contacts" />}
        />
        <Route
          exact
          path="/privacy"
          render={() => <Main uid="privacy" />}
        />
        <Route
          exact
          path="/accessibility-statement"
          render={() => <Main uid="accessibility-statement" />}
        />
        <Route
          exact
          path="/sweepstakes-previous-winners"
          render={() => <Main uid="sweepstakes-previous-winners" />}
        />
        <Route
          exact
          path="/sweepstakes"
          render={() => <Main uid="sweepstakes" />}
        />
        <Route
          exact
          path="/terms-and-conditions"
          render={() => <Main uid="terms-and-conditions" />}
        />
        <Route
          exact
          path="/fraud-alerts"
          render={() => <Main uid="fraud-alerts" />}
        />
        <Route
          exact
          path="/brinkerguestwifi"
          render={() => <Main uid="brinkerguestwifi" />}
        />
        <Route exact path="/nevada-opt-out" component={NevadaLegal} />
        <Route
          exact
          path="/nevada-opt-out-submit"
          component={NevadaLegalSubmit}
        />
        <Route path="/preview" component={RedirectorPreview} />
        <Route path="/brinkercom/*" component={ApiDisplay} />
        <Route render={() => <Main uid="no-match-page" />} />
      </Switch>
    </div>
  );
}

export default App;
